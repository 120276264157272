import * as React from "react";
import Aboutheader from "../components/about/header";
import NavBar from "../components/navigation";
import Aboutfeature from "../components/about/feature";
import Stats from "../components/about/stats";
import Aboutservices from "../components/about/services";
import Footer from "../components/footer";
import IndoorFacilities from "../components/about/indoorfacilities";
import Teams from "../components/about/team";
import GoTop from "../components/goTop";
import WhatsappButton from "../components/whatsapp";
import Seo from "../components/seo";
import { graphql } from "gatsby";

const About = ({ data }) => {
  const description = `Our Early Childhood Center is KHDA approved with EYFS British Curriculum in AI Mankhool. The center is equipped with firendly & qualified staff, excellent facilities and a healthy, safe and secure environment.`;

  const {
    heroSection,
    indoorFacilities,
    listFacilities,
    outdoorFacilities,
    seo,
  } = data.gcms.abouts[0];

  const { navigation } = data.gcms.links[0];

  return (
    <>
      <Seo description={description} title={`About`} />
      <NavBar navigation={navigation} textcolor="text-black" />
      <Aboutheader
        title={heroSection.title}
        description={heroSection.description}
      />
      <Stats />
      <Aboutfeature header={listFacilities[0]} list={listFacilities.slice(1)} />
      <Aboutservices
        header={outdoorFacilities[0]}
        subFacilities={outdoorFacilities.slice(1)}
      />
      <IndoorFacilities
        header={indoorFacilities[0]}
        subFacilities={indoorFacilities.slice(1)}
      />
      {/* <Teams /> */}
      <WhatsappButton />
      <GoTop />
      <Footer navigation={navigation} />
    </>
  );
};

export const query = graphql`
  query aboutQueryAr {
    gcms {
      abouts(locales: ar_AE) {
        heroSection {
          title
          description
        }
        seo {
          pageTitle
          pageDescription
          lang
        }
        listFacilities {
          ... on GraphCMS_BasicComponent {
            title
            description
          }
          ... on GraphCMS_FacilitiesList {
            id
            facilitieTitle
          }
        }
        outdoorFacilities {
          ... on GraphCMS_BasicComponent {
            title
            description
          }
          ... on GraphCMS_SubBasicComponentWImg {
            id
            subtitle
            subDescription
          }
        }
        indoorFacilities {
          ... on GraphCMS_BasicComponent {
            title
            description
          }
          ... on GraphCMS_SubBasicComponentWImg {
            id
            subtitle
            subDescription
            facilitieImage {
              url
            }
          }
        }
      }
      links(locales: ar_AE) {
        navigation {
          name
          slug
          id
        }
      }
    }
  }
`;

export default About;
